  const visa_country = [
    "AFGHANISTAN",
    "ALBANIA",
    "ALGERIA",
    "ANDORRA",
    "ANGOLA",
    "ANTIGUA & BARBUDA",
    "ARGENTINA",
    "ARMENIA",
    "AUSTRALIA",
    "AUSTRIA",
    "AZERBAIJAN",
    "BAHAMAS",
    "BAHRAIN",
    "BANGLADESH",
    "BARBADOS",
    "BELGIUM",
    "BELIZE",
    "BENIN",
    "BERMUDA",
    "BHUTAN",
    "BOLIVIA",
    "BOSNIA AND HERZEG",
    "BOTSWANA",
    "BRAZIL",
    "BRITAIN",
    "BRUNEI",
    "BULGARIA",
    "BURKINA FASO",
    "BURMA UNION MYANMAR",
    "BURUNDI",
    "CABO VERDE",
    "CAMBODIA",
    "CAMEROON",
    "CANADA",
    "CENTRAL AFRICA REP",
    "CHAD",
    "CHILE",
    "CHINA",
    "COLOMBIA",
    "COMONWEALTH DOMINICA",
    "COMOROS",
    "CONGO",
    "COSTARICA",
    "CROATIA",
    "CUBA",
    "CYPRUS",
    "CZECH",
    "DAGHYSTAN",
    "DAHOOMI",
    "DEMOCRATIC REPUBLIC OF THE CONGO",
    "DENMARK",
    "DJIBOUTI",
    "DOMINICAN",
    "ECUADOR",
    "EGYPT",
    "EL SALVADOR",
    "EMIRATES",
    "ENTIAGO",
    "Equatorial Guinea",
    "ERITREN",
    "ESTONIA",
    "ETHIOPIA",
    "Faroese",
    "FIJI",
    "FINLAND",
    "FRANCE",
    "FRENCH GUIANA",
    "GABON",
    "GAMBIA",
    "GEORGIA",
    "GERMANY",
    "GHANA",
    "GHINIA BISSAU",
    "GREECE",
    "GREENLAND",
    "GRENADA",
    "GUATAMALA",
    "GUYANA",
    "HAITI",
    "HOLLAND",
    "HONDURAS",
    "HONG KONG",
    "HUNGARY",
    "ICELAND",
    "INDIA",
    "INDONESIA",
    "INT",
    "IRAN",
    "IRAQ",
    "IRELAND",
    "ISRAEL",
    "ITALY",
    "IVORY COAST",
    "JAMAICA",
    "JAPAN",
    "JORDAN",
    "KAIMAN ISLAN",
    "KALDUNIA NEW",
    "KAZAKHESTAN",
    "KENYA",
    "KINGSTONE",
    "KIRIBATI",
    "KOSOVA",
    "KURDISH",
    "KUWAIT",
    "Kyrgyzstani",
    "LAOS",
    "LATVIA",
    "LATVIA RES",
    "LEBANON",
    "LESOTHO",
    "LIBERIA",
    "LIBYA",
    "LIECHTENSTEIN",
    "LITHUANIA",
    "LUXEMBOURG",
    "MACAU",
    "MADAGASCAR",
    "MALAWI",
    "MALAYSIA",
    "MALDIVES",
    "MALI",
    "MALTA",
    "MARSHALL ISLAND",
    "MARTINIQUE",
    "MARYANA ISLAND",
    "MAURITANIA",
    "MAURITIUS",
    "MEXICO",
    "MICRONESIA",
    "MOLDAVIA",
    "MONACO",
    "MONGOLIA",
    "MONTENEGRO",
    "MOROCCO",
    "MOZAMBIQUE",
    "NAMIBIA",
    "NAURU",
    "NEPAL",
    "NEW GHINIA",
    "NEW ZEALAND",
    "NICARAGUA",
    "NIGER",
    "NIGERIA",
    "NORTH KOREA",
    "NORWAY",
    "PAKISTAN",
    "PALAU",
    "PALESTINE",
    "PANAMA",
    "PAPUA NEW GUINEA",
    "PARAGUAY",
    "PERU",
    "PHILIPPINES",
    "POLAND",
    "PORTUGAL",
    "PUERTO RICO",
    "QATAR",
    "REPUBL. OF MACEDONIA",
    "REPUBLIC OF BELARUS",
    "REPUBLIC OF SOMALILAND",
    "REPUPLIC OF GUINEA",
    "ROMANIA",
    "RWANDA",
    "RUSSIA",
    "SAINT LUCIA",
    "SAINT VINSENT",
    "SAN MARINO",
    "SAO TOME",
    "SAUDI ARABIA",
    "SENEGAL",
    "SICHEL",
    "SIERRA LEONE",
    "SINGAPORE",
    "SLOVAKIA",
    "SLOVENIA",
    "SOLOMON ISLAND",
    "SOMALIA",
    "SOUTH AFRICA",
    "SOUTH KOREA",
    "SOUTH SUDAN",
    "SPAIN",
    "SRI LANKA",
    "ST CHRISTOPHER",
    "ST HELENA",
    "ST KITTS-NAVIS",
    "SUDAN",
    "SULTANATE OF OMAN",
    "SERBIA",
    "SURINAME",
    "SWAZILAND",
    "SWEDEN",
    "SWIZERLAND",
    "SYRIA",
    "TAHITI",
    "TAIWAN",
    "TAJIKISTAN",
    "TANZANIA",
    "TASMANIA",
    "THAILAND",
    "TIMOR LESTE",
    "TOGO",
    "TONGA",
    "TRINIDAD",
    "TUNISIA",
    "TURKEY",
    "TURKMENISTAN",
    "TUVALU",
    "UNITED ARAB EMIRATES",
    "UNITED STATES OF AMERICA",
    "UGANDA",
    "UKRAINE",
    "United Nations",
    "URGWAY",
    "UZBAKISTAN",
    "VANUATU",
    "VATICAN",
    "VENEZUELA",
    "VIETNAM",
    "W SAMOA",
    "YEMEN",
    "YUGOSLAVIA",
    "ZAMBIA",
    "ZIMBABWE",
  ];

export default visa_country;
