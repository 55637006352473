import logo from "../assets/images/logo.png";
import axios from "axios";
import { post as HelperPost } from "../API/apiHelper";

export async function apiurl(callback) {
  let setting = null;
  let settingFromSession = sessionStorage.getItem("settting");
  if (settingFromSession && settingFromSession != null) {
    setting = JSON.parse(settingFromSession);
  }

  callback(
    setting.razorpay_prod_on === 1
      ? setting.razorpay_prod_key
      : setting.razorpay_key
  );
}
const openWindow = (id,amount,RefNo, callback) => {
  const newWindow = window.open(
    id,
    "_blank",
    "width=800,height=600"
  );

  if (newWindow) {
    // Start monitoring the window every second
    const interval = setInterval(() => {
      try {
        if (!newWindow || newWindow.closed) {
          // console.log("✅ Window closed by user or script.");
          clearInterval(interval);
        } else {
          let currentURL = "";
          try {
            currentURL = newWindow.location.href || "";
            console.log(`currentURLcurrentURLcurrentURL ${currentURL}`);
          } catch (error) {
            console.log(error);
          }

          // Close if URL changes OR contains "yogesh.com"
          if (
            // currentURL !== "https://api.vivantravels.com/sucess" ||
            currentURL.includes("/sucess")
          ) {
            const url = new URL(currentURL);
            const params = new URLSearchParams(url.search);

            // Convert URL parameters to an object
            const queryParams = {};
            params.forEach((value, key) => {
              queryParams[key] = value;
            });

            // console.log("✅ URL Parameters:", queryParams);
            if (queryParams["status"] === "success"){
              callback(
                { razorpay_payment_id: queryParams["easepayid"] || "n/a" },
                amount,
                RefNo
              );}
            // console.log(
            //   "🔴 Auto-closing window: URL changed or matched 'yogesh.com'."
            // );
            newWindow.close();
            clearInterval(interval);
          }
        }
      } catch (error) {
        // console.warn(
        //   "⚠️ Cannot access the URL due to cross-origin restrictions."
        // );
        // Even if cross-origin access is blocked, the window object can still be closed safely
        if (newWindow.closed) {
          clearInterval(interval);
        }
      }
    }, 1000); // Check every second
  } else {
    alert("Popup blocked! Please allow popups for this site.");
  }
};

// Function to manually close the opened window
const closeWindow = (popupWindow) => {
  if (popupWindow && !popupWindow.closed) {
    popupWindow.close();
  } else {
    alert("No open window found.");
  }
};

export async function razarpaypayment(
  orderId,
  amount,
  description,
  RefNo,
  callback
) {
  var data = {
    txnid: Math.floor(Date.now() / 1000),
    amount: parseFloat(amount).toFixed(2),
    productinfo: description,
    firstname: description,
    phone: "8058164478",
    email: "wsd@gg.com",
  };
  const res = await HelperPost("third_party/payment", data, true);
  const resval = await res.json();
      if (resval.data.status == 1) {
  openWindow(resval.url + resval.data.data, amount, RefNo, callback);}
  // let res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
  // if (!res) {
  //     alert("Razorpay SDK failed to load. Are you online?");
  //     return;
  // }
  // let userData = null;
  // let userDataFromSession = sessionStorage.getItem('userData');
  // if (userDataFromSession && userDataFromSession != null) {
  //     userData = JSON.parse(userDataFromSession);
  // }
  // let setting = null;
  // let settingFromSession = sessionStorage.getItem('settting');
  // if (settingFromSession && settingFromSession != null) {
  //     setting = JSON.parse(settingFromSession);
  // }
  // let options = {
  //     key: setting.razorpay_prod_on === 1 ? setting.razorpay_prod_key : setting.razorpay_key,
  //     amount: parseInt(amount * 100),
  //     currency: userData.currency_code || "INR",
  //     name: "Vivan Travels",
  //     description: description,
  //     image: logo,
  //     handler: async (response) =>{
  //       console.log(
  //         `razarpayresponse   ${JSON.stringify(response)} ${
  //           response["razorpay_payment_id"]
  //         } `
  //       );
  //       var data = {
  //         payment_id: response["razorpay_payment_id"],
  //         amount: parseInt(amount * 100),
  //         currency: "INR",
  //         type: setting.razorpay_prod_on === 1,
  //       };
  //       const res = await HelperPost(
  //         "third_party/razorpaycapture",
  //         data,
  //         true
  //       );
  //       console.log(
  //         `razarpayresponse   ${JSON.stringify(response)}   ${JSON.stringify(
  //           res
  //         )}`
  //       );
  //       callback(response, amount, RefNo);
  //     },
  //     prefill: {
  //         name: "Vivan Travels",
  //         email: "test@gmail.com",
  //         contact: userData.mobile_no,
  //     },
  //     notes: {
  //         address: "India",
  //     },
  //     theme: {
  //         color: "#158993",
  //     },
  // };
  // let paymentObject = new window.Razorpay(options);
  // paymentObject.open();
}

let loadScript = (src) => {
  return new Promise((resolve) => {
    let script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};
